import React from "react";
import "../styles/Home.css";
import ParticleBackground from "../components/ParticleBackground";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home-container">
      <ParticleBackground />
      <div className="content">
        <div className="hero-section">
          <h1 className="main-title">Tervetuloa</h1>
          <p className="subtitle">
            Mobiilisovellusten asiantuntija – kokemusta myös pilvipalveluista ja
            web-kehityksestä
          </p>
          <div className="description">
            <p>
              Olen mobiilisovelluskehitykseen erikoistunut asiantuntija, joka
              luo käyttäjälähtöisiä ja teknisesti huippuluokkaisia ratkaisuja.
              Mobiilikehitys on ydinosaamistani, mutta minulla on myös kokemusta
              AWS-palveluista ja fullstack-kehityksestä – ja laajennan
              osaamistani jatkuvasti näillä alueilla.
            </p>
          </div>
          <div className="nav-buttons">
            <Link to="/cv" className="nav-button">
              CV
            </Link>
            <Link to="/contact" className="nav-button secondary">
              Ota yhteyttä
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
