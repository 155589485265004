import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Navigation.css";

const Navigation = () => {
  const location = useLocation();

  return (
    <nav className="floating-nav">
      <div className="nav-links">
        <Link
          to="/"
          className={`nav-link ${location.pathname === "/" ? "active" : ""}`}
        >
          <span className="nav-icon">🏠</span>
          <span className="nav-text">Etusivu</span>
        </Link>
        <Link
          to="/cv"
          className={`nav-link ${location.pathname === "/cv" ? "active" : ""}`}
        >
          <span className="nav-icon">📄</span>
          <span className="nav-text">CV</span>
        </Link>
        <Link
          to="/contact"
          className={`nav-link ${
            location.pathname === "/contact" ? "active" : ""
          }`}
        >
          <span className="nav-icon">✉️</span>
          <span className="nav-text">Yhteystiedot</span>
        </Link>
      </div>
    </nav>
  );
};

export default Navigation;
