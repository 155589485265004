import { useEffect } from "react";

interface ParticleProps {
  x: number;
  y: number;
  baseSize: number;
  size: number;
  velocityX: number;
  velocityY: number;
  color: string;
  opacity: number;
  pulse: number;
  pulseSpeed: number;
  reset: () => void;
  update: () => void;
  draw: (ctx: CanvasRenderingContext2D) => void;
}

function ParticleBackground(): JSX.Element {
  useEffect(() => {
    const canvas = document.getElementById(
      "particle-canvas"
    ) as HTMLCanvasElement;
    const ctx = canvas?.getContext("2d");

    if (!canvas || !ctx) return;

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    setCanvasSize();
    window.addEventListener("resize", setCanvasSize);

    const particles: ParticleProps[] = [];
    const particleCount = Math.min(window.innerWidth / 10, 150);
    const colors = ["#4facfe", "#00f2fe", "#a8edea", "#fed6e3"];

    const createParticle = (): ParticleProps => {
      let x = Math.random() * canvas.width;
      let y = Math.random() * canvas.height;
      let baseSize = Math.random() * 3 + 1;
      let velocityX = (Math.random() - 0.5) * 0.8;
      let velocityY = (Math.random() - 0.5) * 0.8;
      let color = colors[Math.floor(Math.random() * colors.length)];
      let opacity = Math.random() * 0.5 + 0.2;
      let pulse = Math.random() * 0.1;
      let pulseSpeed = 0.01;

      const particle: ParticleProps = {
        x,
        y,
        baseSize,
        size: baseSize,
        velocityX,
        velocityY,
        color,
        opacity,
        pulse,
        pulseSpeed,

        reset() {
          this.x = Math.random() * canvas.width;
          this.y = Math.random() * canvas.height;
          this.baseSize = Math.random() * 3 + 1;
          this.size = this.baseSize;
          this.velocityX = (Math.random() - 0.5) * 0.8;
          this.velocityY = (Math.random() - 0.5) * 0.8;
          this.pulse = Math.random() * 0.1;
          this.pulseSpeed = 0.01;
        },

        update() {
          this.x += this.velocityX;
          this.y += this.velocityY;
          this.size = this.baseSize + Math.sin(this.pulse) * 0.5;
          this.pulse += this.pulseSpeed;

          if (
            this.x < 0 ||
            this.x > canvas.width ||
            this.y < 0 ||
            this.y > canvas.height
          ) {
            this.reset();
          }
        },

        draw(ctx: CanvasRenderingContext2D) {
          ctx.beginPath();
          ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
          ctx.fillStyle = this.color;
          ctx.globalAlpha = this.opacity;
          ctx.fill();
          ctx.globalAlpha = 1;
        },
      };

      return particle;
    };

    for (let i = 0; i < particleCount; i++) {
      particles.push(createParticle());
    }

    const drawConnections = (
      particle1: ParticleProps,
      particle2: ParticleProps,
      distance: number
    ) => {
      const maxDistance = 150;
      const opacity = (1 - distance / maxDistance) * 0.2;
      ctx.beginPath();
      ctx.strokeStyle = `rgba(255, 255, 255, ${opacity})`;
      ctx.lineWidth = 0.5;
      ctx.moveTo(particle1.x, particle1.y);
      ctx.lineTo(particle2.x, particle2.y);
      ctx.stroke();
    };

    const animate = () => {
      ctx.fillStyle = "rgba(10, 10, 30, 0.1)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      particles.forEach((particle) => {
        particle.update();
        particle.draw(ctx);
      });

      for (let i = 0; i < particles.length; i++) {
        for (let j = i + 1; j < particles.length; j++) {
          const dx = particles[i].x - particles[j].x;
          const dy = particles[i].y - particles[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 150) {
            drawConnections(particles[i], particles[j], distance);
          }
        }
      }

      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener("resize", setCanvasSize);
    };
  }, []);

  return (
    <canvas
      id="particle-canvas"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: -1,
        background: "linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)",
      }}
    />
  );
}

export default ParticleBackground;
