import React from "react";
import "../styles/Contact.css";
import ParticleBackground from "../components/ParticleBackground";

const Contact = () => {
  return (
    <div className="contact-container">
      <ParticleBackground />
      <div className="contact-content">
        <h1 className="contact-title">Ota yhteyttä</h1>
        <div className="contact-info">
          <p>Voit ottaa minuun yhteyttä seuraavilla tavoilla:</p>
          <div className="contact-methods">
            <div className="contact-method">
              <h3>Sähköposti</h3>
              <a href="mailto:tmmi.makela@outlook.com">
                tmmi.makela@outlook.com
              </a>
            </div>
            <div className="contact-method">
              <h3>LinkedIn</h3>
              <a
                href="https://www.linkedin.com/in/tommi-makela/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn Profiili
              </a>
            </div>
            <div className="contact-method">
              <h3>GitHub</h3>
              <a
                href="https://github.com/Tommi-dev"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub Profiili
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
